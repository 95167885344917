//@flow
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, Slider, TextField } from '@material-ui/core';
import firebase from './Firebase';
import * as Tone from 'tone'

const TinnitusMatcherContainer = styled.div`
  margin: 0 20px 20px;
  text-align: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.div`
  color: #2F353F;
  font-family: Roboto;
  font-size: 32px;
  margin-top: 20px;
  text-align: center;
`;

const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 800px;
  width: 100%;
`;

const Text = styled.div`
  color: #2F353F;
  font-family: Roboto;
  font-size: 20px;
  margin: 20px;
  text-align: center;
`;

const P = styled.p`
  margin: 0.5em 0;
`;

const lerp = (x, y, a) => x * (1 - a) + y * a;

const synth = new Tone.Synth().toDestination();
const lowerToneBound = 100;
const upperToneBound = 18000;
const initialToneValue = 1550;

const initalVolumeValue = .2;
const minVolume = -80;
const maxVolume = -10;
const initalVolumeValueDb = lerp(minVolume, maxVolume, initalVolumeValue);
synth.volume.value = initalVolumeValueDb;

const TinnitusMatcher = ({ uuid, isParticipant }) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const [tone, setTone] = useState(initialToneValue);
  const [volume, setVolume] = useState(initalVolumeValueDb);
  const [qualitativeFeedback, setQualitativeFeedback] = useState('');
  const [showSubmitNotification, setShowSubmitNotification] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const onToneSliderChanged = (event, value) => {
    const freq = (10 ** value);
    if (!isPlaying) {
      synth.triggerAttack(freq);
      setIsPlaying(true);
    } else {
      synth.frequency.value = freq;
    }
    if (!canSubmit) setCanSubmit(true);
  };

  const onToneSliderChangeCommitted = (event, value) => {
    const freq = (10 ** value);
    setTone(freq);
  }

  const formatToneLabel = (x) => {
    x = Math.round(x);
    return x < 1000 ? x : `${(x / 1000).toPrecision(2)}k`;
  };

  const ToneSlider = (
    <SliderContainer>
      <Text>Tone</Text>
      <Slider
        aria-labelledby="discrete-slider"
        defaultValue={Math.log(initialToneValue) / Math.log(10)}
        max={Math.log(upperToneBound) / Math.log(10)}
        min={Math.log(lowerToneBound) / Math.log(10)}
        onChange={onToneSliderChanged}
        onChangeCommitted={onToneSliderChangeCommitted}
        scale={(x) => 10 ** x}
        step={.0001}
        valueLabelDisplay="auto"
        valueLabelFormat={formatToneLabel}
      />
    </SliderContainer>
  );

  const onVolumeSliderChanged = (event, value) => {
    const vol = lerp(minVolume, maxVolume, value);
    if (!isPlaying) {
      synth.triggerAttack(tone);
      setIsPlaying(true);
    } else {
      synth.volume.value = vol;
    }
    if (!canSubmit) setCanSubmit(true);
  };

  const formatVolumeLabel = (x) => {
    return `${Math.round(x * 100)}%`;
  };

  const onVolumeSliderChangeCommitted = (event, value) => {
    const vol = lerp(minVolume, maxVolume, value);
    setVolume(vol);
  }

  const VolumeSlider = (
    <SliderContainer>
      <Text>Volume</Text>
      <Slider
        aria-labelledby="discrete-slider"
        defaultValue={initalVolumeValue}
        max={1}
        min={0}
        onChange={onVolumeSliderChanged}
        onChangeCommitted={onVolumeSliderChangeCommitted}
        step={.01}
        valueLabelDisplay="auto"
        valueLabelFormat={formatVolumeLabel}
      />
    </SliderContainer>
  );

  const ExplanationText = () => isParticipant ?
    (
      <Text>
        <P>Each week when you complete the questionnaire, also use the tinnitus matcher below to
        match a tone to the sound of your tinnitus.</P>
        <P>You can choose to use your computer or your smartphone. However, you must use the same
        device each week.</P>
        <P>Whichever device you decide to use, please turn the volume <b>on your device</b> up to
        maximum each week during this test. You don't need to keep your device's volume at maximum
        while listening to the daily tones above, just while completing this test once each week.
        Keeping the volume on your device to maximum while changing the volume slider below to the
        appropriate level will allow the measurements to be consistent from week to week. The tone
        will start quietly, and please be careful to slowly increase the volume slider below so that
        you do not reach a volume that is painful to hear.</P>
        <P>To match a tone to your tinnitus, press <b>play</b> to hear a tone and then adjust the
        sliders until the tone matches the sound of your tinnitus.</P>
        <P>Although most people can find a tone that matches their tinnitus, some people have tinnitus
         that is more difficult to match. If this is you, please do your best to find a tone and
         volume that resembles your tinnitus and then describe the difference in the text box below
         before pressing submit.</P>
        <P>Make sure to press <b>submit</b> when you're finished matching the tone to your
        tinnitus.</P>
      </Text>
    ) : (
      <Text>
        <P>Each week when you complete the questionnaire, also use the tinnitus matcher below to
        match a tone to the sound of your tinnitus.</P>
        <P>You can choose to use your computer or your smartphone. However, you must use the same
        device each week.</P>
        <P>Whichever device you decide to use, please turn the volume <b>on your device</b> up to
        maximum each week during this test. You don't need to keep your device's volume at maximum
        while listening to the daily tones above, just while completing this test once each week.
        Keeping the volume on your device to maximum while changing the volume slider below to the
        appropriate level will allow the measurements to be consistent from week to week. The tone
        will start quietly, and please be careful to slowly increase the volume slider below so that
        you do not reach a volume that is painful to hear.</P>
        <P>To match a tone to your tinnitus, press <b>play</b> to hear a tone and then adjust the
        sliders until the tone matches the sound of your tinnitus.</P>
        <P>Although most people can find a tone that matches their tinnitus, some people have tinnitus
         that is more difficult to match. If this is you, please do your best to find a tone and
         volume that resembles your tinnitus and then describe the difference in the text box below
         before pressing submit.</P>
        <P>Make sure to press <b>submit</b> when you're finished matching the tone to your
        tinnitus.</P>
      </Text>
    );

  const PlayButton = () => {

    const onPlay = async () => {
      if (isPlaying) {
        synth.triggerRelease();
      } else {
        synth.triggerAttack(tone);
      }
      setIsPlaying(!isPlaying);
    };

    return (
      <Button
        color="primary"
        onClick={onPlay}
        size="large"
        variant="contained"
        style={{ margin: '5px', width: '102px' }}
      >
        {isPlaying ? "Stop" : "Play"}
      </Button>
    );
  };

  const onSubmit = async () => {
    const userNtmRootRef = firebase.database().ref(`tinnitusMatchingData/${uuid}`);
    const newNtmRef = await userNtmRootRef.push();
    const timestamp = new Date().getTime();
    await newNtmRef.set({
      timestamp,
      tone,
      volume,
      qualitativeFeedback,
    });
    setQualitativeFeedback("");
    subjectiveFeedbackRef.current.value = "";
    setShowSubmitNotification(true);
    setCanSubmit(false);
    if(isPlaying) synth.triggerRelease();
    await new Promise(resolve => setTimeout(resolve, 10000));
    setShowSubmitNotification(false);
  }

  const SubmitButton = ({ disabled }) => (
    <Button
      color="primary"
      disabled={disabled}
      onClick={onSubmit}
      size="large"
      style={{ margin: '5px', width: '102px' }}
      variant="contained"
    >
      Submit
    </Button>
  );

  const subjectiveFeedbackRef = useRef();

  const QualitativeTextInput = (
    <TextField
      fullWidth
      id="subjective-input"
      inputRef={subjectiveFeedbackRef}
      key="subjective-input"
      label="Leave any notes about matching your tinnitus here."
      multiline
      onChange={(event) => setQualitativeFeedback(event.target.value)}
      style={{ margin: "10px 0 10px", maxWidth: '800px' }}
      variant="filled"
    />
  )

  return (
    <TinnitusMatcherContainer>
      <HeaderText>
        Tinnitus Matcher
      </HeaderText>
      <ExplanationText />
      { ToneSlider }
      { VolumeSlider }
      <PlayButton />
      { QualitativeTextInput }
      {showSubmitNotification ?
        <Text style={{ margin: 14 }}>Successfully submitted!</Text> :
        <SubmitButton disabled={!canSubmit} />
       }
    </TinnitusMatcherContainer>
  );
};

export default TinnitusMatcher;
