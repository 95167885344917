//@flow
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, TextField } from '@material-ui/core';
import firebase from './Firebase';
import Questions from './TFI.js';
import { animateScroll as scroll } from 'react-scroll';
import Loader from 'react-loader-spinner';
import QuestionSection from './QuestionSection';

const HeaderText = styled.div`
  color: #2F353F;
  font-family: Roboto;
  font-size: 32px;
  margin-top: 20px;
  text-align: center;
`;

const QuestionnaireContainer = styled.div`
  margin: 0 20px 10px;
`;

const Text = styled.div`
  color: #2F353F;
  font-family: Roboto;
  font-size: 20px;
  margin: 20px;
  text-align: center;
`;

const P = styled.p`
  margin: 0.5em 0;
`;

const PSmall = styled.p`
  font-size: 18px;
  margin: 0.4em 0;
`;

const Margin = styled.div`
  padding: 8px;
`;

const Questionnaire = ({ uuid, onQuestionnaireSubmitted, isParticipant }) => {

  const [lastSubmissionTime, setLastSubmissionTime] = useState(null);
  const [answers, setAnswers] = useState({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [submitContentState, setSubmitContentState] = useState('submit');

  useEffect(() => {
    const fetchLastSubmission = async() => {
      const userQuestionnaireRootRef = firebase.database().ref(`questionnaireData/${uuid}`);
      const latestQuestionnaire = await userQuestionnaireRootRef.orderByKey().limitToLast(1).once('value');
      if (latestQuestionnaire.exists()) {
        const latestSubmissionTime = latestQuestionnaire.val()[Object.keys(latestQuestionnaire.val())[0]].timestamp;
        setLastSubmissionTime(latestSubmissionTime);
      } else {
        setLastSubmissionTime('never');
      }
    }
    fetchLastSubmission();
  }, [uuid]);

  const subjectiveFeedbackRef = useRef();

  const onSubmit = async () => {
    setSubmitContentState('submitting');
    const userQuestionnaireRootRef = firebase.database().ref(`questionnaireData/${uuid}`);
    const newQuestionnaireRef = await userQuestionnaireRootRef.push();
    const timestamp = new Date().getTime();
    const answersLog = {}
    for (var i = 0; i < Questions.length; i++) {
      answersLog[i] = { question: Questions[i].key, answer: answers?.[i] };
    }
    const currentSubjectiveFeedback = subjectiveFeedbackRef.current.value;
    await newQuestionnaireRef.set({
      timestamp,
      answers: answersLog,
      subjectiveFeedback: currentSubjectiveFeedback,
    });
    setLastSubmissionTime(timestamp);
    setAnswers({});
    subjectiveFeedbackRef.current.value = "";
    setSubmitContentState('submitted');
    await new Promise(resolve => setTimeout(resolve, 500));
    scroll.scrollToTop();
    await new Promise(resolve => setTimeout(resolve, 250));
    onQuestionnaireSubmitted();
  }

  const SubmitButton = ({ disabled }) => (
    <Button
      color="primary"
      disabled={disabled}
      onClick={onSubmit}
      size="large"
      variant="contained"
    >
      Submit
    </Button>
  );

  const LastSubmissionText = () => {
    const isToday = (someDate) => {
      const today = new Date()
      return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    }

    let lastSubmissionText = "...";
    let nextSubmissionText = "...";
    if (lastSubmissionTime) {
      if (lastSubmissionTime === 'never') {
        lastSubmissionText = "You have never completed the weekly questionnaire.";
        nextSubmissionText = "Complete the following questionnaire to start tracking your progress."
      } else {
        const date = new Date(lastSubmissionTime);
        const options = {weekday: 'long', month: 'long', day: 'numeric'};
        const dateString = date.toLocaleDateString(undefined, options);
        lastSubmissionText = `You last completed the weekly questionnaire on ${dateString}.`;
        let nextDate = new Date(date.valueOf());
        nextDate.setDate(date.getDate() + 7);
        const nextDateString = nextDate.toLocaleDateString(undefined, options);
        nextSubmissionText = `Please complete the next questionnaire on ${nextDateString}.`;
        if (isToday(date)) {
          lastSubmissionText = `You completed the weekly questionnaire today.`;
          nextSubmissionText = `Please complete the next questionnaire in one week on ${nextDateString}.`;
        } else if (isToday(nextDate)) {
          nextSubmissionText = `Please complete the questionnaire today, ${nextDateString}.`;
        } else if (nextDate < Date.now()) {
          nextSubmissionText = isParticipant ?
          'You are late to complete the weekly questionnaire. Please complete it today to stay in the study.' :
          'It has been over a week since you last completed the questionnaire.';
        }
      }
    }
    return (
      <Text>
        <PSmall>{lastSubmissionText}</PSmall>
        <PSmall><b>{nextSubmissionText}</b></PSmall>
        <Margin />
        <P>When completing the questionnaire, please think about the <b>past week only</b>.</P>
      </Text>
    )
  };

  const SubmitButtonContent = () => {
    const blankSpace = (
      <div style={{
        clear: 'both',
        height: 21,
        margin: 6,
        width: '100%',
      }} />
    );
    switch (submitContentState) {
      case 'submit':
      default:
        return (
          <>
            <SubmitButton disabled={!canSubmit} />
            {canSubmit ? (
              blankSpace
              ) : (
              <Text
                style={{
                  margin: 6,
                  color: "#bbbbbb",
                  fontSize: 18,
                }}
              >Please answer all questions before submitting.</Text>
              )
            }
          </>
          )
      case 'submitting':
        return (
          <>
            <Loader
              type="Oval"
              color="#3f51b5"
              height={38}
              width={38}
            />
            {blankSpace}
          </>
        )
    case 'submitted':
      return (
        <>
          <Text style={{ margin: 8 }}>Successfully submitted!</Text>
          {blankSpace}
        </>
        )
    }
  }

  const updateAnswers = (index, value) => {
    const updatedAnswers = { ...answers, [index]: value };
    setCanSubmit(Object.keys(updatedAnswers).length === Questions.length);
    setAnswers(updatedAnswers);
  }

  return(
    <>
      <HeaderText>
        {
          process.env.REACT_APP_USER === 'customer' ?
          'Weekly Tinnitus Functional Index' :
          'Weekly Questionnaire'
        }
      </HeaderText>
      <LastSubmissionText />
      <QuestionnaireContainer>
        {Questions.map((q, i) => <QuestionSection
          key={q.key}
          question={q}
          index={i}
          updateAnswers={updateAnswers}
        />)}
        <TextField
          fullWidth
          id="subjective-input"
          inputRef={subjectiveFeedbackRef}
          key="subjective-input"
          label="What changes have you noticed in your tinnitus over the past week? (Optional)"
          multiline
          style={{ margin: "0 0 12px" }}
          variant="outlined"
        />
      </QuestionnaireContainer>
      <SubmitButtonContent />
    </>
  );
}

export default Questionnaire;
