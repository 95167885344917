const Questions = [
  {
    question: "What percentage of your time awake were you consciously aware of your tinnitus?",
    format: "percentage",
    minText: "Never aware",
    maxText: "Always aware",
    key: "aware",
  },
  {
    question: "How strong or loud was your tinnitus?",
    format: "integer",
    minText: "Not at all strong or loud",
    maxText: "Extremely strong or loud",
    key: "loud",
  },
  {
    question: "What percentage of your time awake were you annoyed by your tinnitus?",
    format: "percentage",
    minText: "None of the time",
    maxText: "All of the time",
    key: "annoyed",
  },
  {
    question: "Did you feel in control in regard to your tinnitus?",
    format: "integer",
    minText: "Very much in control",
    maxText: "Never in control",
    key: "in control",
  },
  {
    question: "How easy was it for you to cope with your tinnitus?",
    format: "integer",
    minText: "Very easy to cope",
    maxText: "Impossible to cope",
    key: "cope",
  },
  {
    question: "How easy was it for you to ignore your tinnitus?",
    format: "integer",
    minText: "Very easy to ignore",
    maxText: "Impossible to ignore",
    key: "ignore",
  },
  {
    question: "How much has your tinnitus interfered with your ability to concentrate?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "concentrate",
  },
  {
    question: "How much has your tinnitus interfered with your ability to think clearly?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "think clearly",
  },
  {
    question: "How much has your tinnitus interfered with your ability to focus attention on other things besides your tinnitus?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "focus",
  },
  {
    question: "How often did your tinnitus make it difficult to fall asleep or stay asleep?",
    format: "integer",
    minText: "Never had difficulty",
    maxText: "Always had difficulty",
    key: "fall asleep",
  },
  {
    question: "How often did your tinnitus cause you difficulty in getting as much sleep as you needed?",
    format: "integer",
    minText: "Never had difficulty",
    maxText: "Always had difficulty",
    key: "get enough sleep",
  },
  {
    question: "How much of the time did your tinnitus keep you from sleeping as deeply or as peacefully as you would have liked?",
    format: "integer",
    minText: "None of the time",
    maxText: "All of the time",
    key: "sleep deeply",
  },
  {
    question: "How much has your tinnitus interfered with your ability to hear clearly?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "hear clearly",
  },
  {
    question: "How much has your tinnitus interfered with your ability to understand people who are talking?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "understand speech",
  },
  {
    question: "How much has your tinnitus interfered with your ability to follow conversations in a group or at meetings?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "follow conversations",
  },
  {
    question: "How much has your tinnitus interfered with your quiet resting activities?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "resting activities",
  },
  {
    question: "How much has your tinnitus interfered with your ability to relax?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "relax",
  },
  {
    question: "How much has your tinnitus interfered with your ability to enjoy peace and quiet?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "peace and quiet",
  },
  {
    question: "How much has your tinnitus interfered with your enjoyment of social activities?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "social activities",
  },
  {
    question: "How much has your tinnitus interfered with your enjoyment of life?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "enjoyment of life",
  },
  {
    question: "How much has your tinnitus interfered with your relationships with family, friends, and other people?",
    format: "integer",
    minText: "Did not interfere",
    maxText: "Completely interfered",
    key: "relationships",
  },
  {
    question: "How often did your tinnitus cause you to have difficulty performing your work or other tasks, such as home maintenance, school work, or caring for children or others?",
    format: "integer",
    minText: "Never had difficulty",
    maxText: "Always had difficulty",
    key: "work",
  },
  {
    question: "How anxious or worried has your tinnitus made you feel?",
    format: "integer",
    minText: "Not at all anxious or worried",
    maxText: "Extremely anxious or worried",
    key: "anxious",
  },
  {
    question: "How bothered or upset have you been because of your tinnitus?",
    format: "integer",
    minText: "Not at all bothered or upset",
    maxText: "Extremely bothered or upset",
    key: "bothered",
  },
  {
    question: "How depressed were you because of your tinnitus?",
    format: "integer",
    minText: "Not at all depressed",
    maxText: "Extremely depressed",
    key: "depressed",
  },
];

export default Questions;
