import React, { useState } from 'react';
import styled from 'styled-components';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

const QuestionContainer = styled.div`
  background-color: #f7f7f7;
  border-radius: 6px;
  box-shadow: 4px 2.5px 3px #dddddd;
  font-size: 18px;
  margin: 12px 0;
  padding: 10px 20px;
`;

const QuestionAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModifierContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 425px){
    justify-content: space-between;
  }
`;

const QuestionModifierText = styled.div`
  font-size: 16px;
  text-align: center;
  width: 120px;

  @media (max-width: 425px){
    font-size: 14px;
  }
`;

const P = styled.p`
  margin: 0.5em 0;
`;

const QuestionSection = ({
  answersRef,
  index,
  question,
  updateAnswers,
}) => {
  const [answer, setAnswer] = useState();

  const handleChange = async(event, value) => {
    const valueInt = parseInt(value);
    setAnswer(valueInt);
    updateAnswers(index, valueInt);
  }

  function labelFormat(value, format) {
    switch (format) {
      case "integer":
      default:
        return value;
      case "percentage":
        return `${value * 10}${value === 0 ? "%" : ""}`;
    }
  }

  const RadioButtons = () => (
    <RadioGroup
      aria-label={`tfiRadioGroup${index}`}
      name={`tfiRadioGroup${index}`}
      onChange={handleChange}
      row
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        height: 'auto',
        justifyContent: 'space-between',
        width: '100%',
      }}
      value={`${answer}` || 'n/a'}
    >
      {[...Array(11).keys()].map(
        (i) =>
        <FormControlLabel
          value={`${i}`}
          control={<Radio color="primary" />}
          label={labelFormat(i, question.format)}
          labelPlacement="bottom"
          style={{margin: 0, width: '25px'}}
          key={`${index}_${i}`}
        />
        )}
    </RadioGroup>
  );

  const isMobile = window.innerWidth <= 500;

  return (
    <QuestionContainer key={question.key}>
      <P style={{ maxWidth: "720px" }}>{ `${index + 1}. ${question.question}` }</P>
      <QuestionAnswerContainer>
        <ModifierContainer>
          <QuestionModifierText style={{ margin: "10px 10px 8px", textAlign: 'left' }}>{ question.minText }</QuestionModifierText>
          {!isMobile && <RadioButtons />}
          <QuestionModifierText style={{ margin: "10px 10px 8px", textAlign: 'right' }}>{ question.maxText }</QuestionModifierText>
        </ModifierContainer>
        {isMobile && <RadioButtons />}
      </QuestionAnswerContainer>
    </QuestionContainer>
  );
};

export default QuestionSection;
