// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 375,
  },
});

const UserDetailsTable = ({ users }) => {

  const millisecondsToHhMmSs = (ms) => {
    const seconds = (Math.floor(ms / 1000) % 60).toString().padStart(2, 0);
    const minutes = (Math.floor(ms / (1000 * 60)) % 60).toString().padStart(2, 0);
    const hours = Math.floor(ms / (1000 * 60 * 60));
    return `${hours}:${minutes}:${seconds}`;
  }

  const listeningDurationFromData = (data) => {
    if (!data?.pause || !data?.play) return "--";
    const pauses = Object.keys(data.pause)
      .filter(k => data.pause[k])
      .map(s => parseInt(s))
      .sort((a, b) => a - b);
    const plays = Object.keys(data.play)
      .filter(k => data.play[k])
      .map(s => parseInt(s))
      .sort((a, b) => a - b);
    let duration = 0;
    let pause = 0;
    let play = 0;
    while (true) {
      if (pause >= pauses.length || play >= plays.length) break;
      while (pauses[pause] < plays[play]) { // Our pause must be after our play
        pause += 1;
        if (pause >= pauses.length) break;
      }
      while (play + 1 < plays.length && plays[play + 1] < pauses[pause]) { // Our play must be the play closest to our pause
        play += 1;
      }
      duration += pauses[pause] - plays[play];
      pause += 1;
      play += 1;
    }
    return millisecondsToHhMmSs(duration);
  }

  const dateFromTimestamp = (timestamp) => {
    return (new Date(timestamp)).toLocaleDateString();
  }

  const timeFromTimestamp = (timestamp) => {
    return (new Date(timestamp)).toLocaleString();
  }

  const mostRecentListen = (data) => {
    if (!data) return "--";
    const plays = Object.keys(data.play)
      .filter(k => data.play[k])
      .map(s => parseInt(s))
      .sort((a, b) => a - b);
    return timeFromTimestamp(plays[plays.length - 1]);
  }

  const getConditionText = (data) => {
    if (!data || data.isControl === undefined) return "-";
    return data.isControl ? "control" : "wristband";
  }

  const classes = useStyles();
  const rows = Object.keys(users)
  .map((user) => ({
    passcode: user,
    listeningDuration: listeningDurationFromData(users[user].data),
    mostRecentListen: mostRecentListen(users[user].data),
    condition: getConditionText(users[user]),
    ...users[user],
  }))
  .sort((a, b) => b.creationTime - a.creationTime);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell align="right">Passcode</TableCell>
            <TableCell align="right">Date Added</TableCell>
            <TableCell align="right">Last Listen</TableCell>
            <TableCell align="right">Listening Duration</TableCell>
            <TableCell align="right">Condition</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.passcode}>
              <TableCell component="th" scope="row">
                {row.username}
              </TableCell>
              <TableCell align="right">{row.passcode}</TableCell>
              <TableCell align="right">{dateFromTimestamp(row.creationTime)}</TableCell>
              <TableCell align="right">{row.mostRecentListen}</TableCell>
              <TableCell align="right">{row.listeningDuration}</TableCell>
              <TableCell align="right">{row.condition}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserDetailsTable;
