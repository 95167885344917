import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Slider } from '@material-ui/core';
import * as Tone from 'tone'

const Text = styled.div`
  color: #2F353F;
  font-family: Roboto;
  font-size: 20px;
  margin: 20px;
  text-align: center;
`;

const P = styled.p`
  margin: 0.5em 0;
`;

const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 800px;
  width: 100%;
`;

const synth = new Tone.Synth().toDestination();
// TODO: deal with fact that we ask users to set vol very high on tinn matcher
synth.volume.value = -20;
Tone.Transport.bpm.value = 240 * 2;

const DynamicTones = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [loop, setLoop] = useState(null);
  const [lowerFreq, setLowerFreq] = useState('350');
  const [upperFreq, setUpperFreq] = useState('8000');

  const lowerToneBound = 100;
  const upperToneBound = 18000;
  const initialToneValue = 1550;

  const [centralTone, setTone] = useState(initialToneValue);

  const getRandomInRange = (min, max) => {
    const v = Math.random() * (max - min) + min;
    return v;
  };

  useEffect(() => {
    const getTones = (center, scale) => {
      let relativeHalfsteps;
      switch (scale) {
        case "chromatic":
        default:
          relativeHalfsteps = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6];
          break;
        case "minor":
          relativeHalfsteps = [-5, -3, -2, 0, 2, 3, 5, 7];
          break;
        case "major":
          relativeHalfsteps = [-5, -3, -1, 0, 2, 4, 6, 7];
          break;
        case "pentatonic":
          relativeHalfsteps = [-5, -3, -1, 2, 4, 7];
          break;
        case "kevin":
          relativeHalfsteps = [-5, -3, -1, 2, 4, 6, 7];
          break;
      }
      return relativeHalfsteps.map(halfstep => center * Math.pow(2, halfstep / 12));
    };
    if (loop) {
      loop.stop();
    }
    const tones = getTones(centralTone, "chromatic");
    const newLoop = new Tone.Pattern((time, note) => {
      synth.triggerAttackRelease(note, getRandomInRange(.15, .35), time + getRandomInRange(0, .15));
    }, tones, "randomOnce");
    newLoop.interval = .5;
    setLoop(newLoop);
    Tone.Transport.start();

    const minFreqDifference = 2300;
    const minFreq = 350;
    const maxFreq = 8000;
    let firstTone = tones[0] - 100;
    let lastTone = tones[tones.length - 1] + 100;
    const range = lastTone - firstTone;
    if (range < minFreqDifference) {
      const padding = (minFreqDifference - range) / 2;
      firstTone -= padding;
      lastTone += padding;
      if (firstTone < minFreq) {
        const adjustment = minFreq - firstTone;
        firstTone += adjustment;
        lastTone += adjustment;
      } else if (lastTone > maxFreq) {
        const adjustment = lastTone - maxFreq;
        firstTone -= adjustment;
        lastTone -= adjustment;
      }
    }
    setLowerFreq(Math.round(firstTone.toFixed(0) / 10) * 10);
    setUpperFreq(Math.round(lastTone.toFixed(0) / 10) * 10);
  }, [centralTone]);

  const onPlay = async () => {
    Tone.start();
    if (isPlaying) {
      loop.stop();
    } else {
      loop.start();
    }
    setIsPlaying(!isPlaying);
  };

  const ExplanationText = () => (
    <Text>
      <P>These tones are specific to your tinnitus. This means you must adjust the microphone on your Buzz wristband.</P>
      <P>To do so, install the Neosensory app from <a href="https://play.google.com/store/apps/details?id=com.neosensory">Google Play</a> or the <a href="https://apps.apple.com/us/app/neosensory/id1480756997">App Store</a>.</P>
      <P>After following the instructions in the app to connect to your wristband, press the three lines in the top left corner of the screen to open the menu. Then, press Device Management and then Buzz Settings. Press Frequency Range and then Continue.</P>
      <P>Adjust the slider on the Frequency Range page so that the lower slider is approximately {lowerFreq}Hz and the upper slider is approximately {upperFreq}Hz.</P>
      <P>Your wristband is now tuned to your tinnitus and you are ready to listen to the tones below.</P>
    </Text>
  );

  const onToneSliderChangeCommitted = (event, value) => {
    const freq = (10 ** value);
    setTone(freq);
  }

  const formatToneLabel = (x) => {
    x = Math.round(x);
    return x < 1000 ? x : `${(x / 1000).toPrecision(2)}k`;
  };

  const FreqSlider = () => (
    <SliderContainer>
      <Text>Tone</Text>
      <Slider
        aria-labelledby="discrete-slider"
        defaultValue={Math.log(initialToneValue) / Math.log(10)}
        value={Math.log(centralTone) / Math.log(10)}
        max={Math.log(upperToneBound) / Math.log(10)}
        min={Math.log(lowerToneBound) / Math.log(10)}
        onChangeCommitted={onToneSliderChangeCommitted}
        scale={(x) => 10 ** x}
        step={.0001}
        valueLabelDisplay="auto"
        valueLabelFormat={formatToneLabel}
      />
    </SliderContainer>
  )

  return (
    <>
      <ExplanationText />
      <Button
        color="primary"
        onClick={onPlay}
        size="large"
        variant="contained"
        style={{ margin: '5px', width: '102px' }}
      >
        {isPlaying ? "Stop" : "Play"}
      </Button>
      <FreqSlider />
    </>
  );
};

export default DynamicTones;
