//@flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import firebase from './Firebase';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import * as d3 from 'd3';

const HeaderText = styled.div`
  color: #666666;
  font-family: Roboto;
  font-size: 28px;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
`;

const LineBreak = styled.hr`
  width: 90%;
`;

const DataPlots = ({ uuid, latestQuestionnaireUpdate }) => {

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async() => {
      const userQuestionnaireRootRef = firebase.database().ref(`questionnaireData/${uuid}`);
      const questionnaireDataNode = await userQuestionnaireRootRef.once('value');
      const questionnaireData = questionnaireDataNode.exists() && questionnaireDataNode.val();
      const data = [];
      for (const value of Object.values(questionnaireData)) {
        const answerSum = value.answers.reduce((sum, answerObj) => (
          sum + answerObj.answer
        ), 0) / 2.5;
        data.push({
          date: value.timestamp,
          score: answerSum,
        });
      }
      if (data.length < 1) return;
      data.sort((a, b) => (a.date - b.date));
      const originalScore = data[0].score;
      data.forEach((item, i) => {
        item.delta = item.score - originalScore;
      });
      setData(data);
    }
    fetchData();
  }, [uuid, latestQuestionnaireUpdate]);

  const Chart = () => {
    const timeFormatter = (tick) => {return d3.timeFormat('%b %d')(new Date(tick));};
    const labelFormatter = (tick) => {return d3.timeFormat('%B %-d %-I:%M %p')(new Date(tick));};
    const tooltipFormatter = (value, name, props) => {return [value, "Tinnitus severity", ] }
    const aspectRatio = window.screen.width < 700 ? 1.5 : 3;
    const width = window.screen.width < 700 ? "90%" : "60%";
    return (
      <ResponsiveContainer width={width} aspect={aspectRatio} >
        <LineChart
          data={data}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Line type="monotone" dataKey="score" stroke="#757de8" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis
            dataKey="date"
            tickFormatter={timeFormatter}
          />
          <YAxis
            allowDecimals={false}
            label={{ value: "Tinnitus Severity", angle: -90, dx: -15, fill: '#666666' }}
            rotation="90"
          />
          <Tooltip labelFormatter={labelFormatter} formatter={tooltipFormatter} />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  if (!data) {
    return <></>;
  }

  return (
    <>
      <LineBreak />
      <HeaderText>
        Your Questionnaire Results
      </HeaderText>
      <Chart />
    </>
  );
};

export default DataPlots;
