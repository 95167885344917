import * as firebase from 'firebase';

export const firebaseConfig = {
  apiKey: 'AIzaSyBWjDHh_GxQrbBSXZTnmbC3TYLjgCyZcXk',
  authDomain: 'tinnitus-test-d8507.firebaseapp.com',
  databaseURL: 'https://tinnitus-test-d8507.firebaseio.com',
  projectId: 'tinnitus-test-d8507',
  storageBucket: 'tinnitus-test-d8507.appspot.com',
  messagingSenderId: '692336497192',
  appId: '1:692336497192:web:a28949334f4908c512db1d'
};

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
